import React, { useState } from 'react';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import JobPosting from '../components/JobPosting';

import colors from '../utils/colors';
import { Typography } from '@mui/material';
import Footer from '../components/Footer';
import backgroundImg from '../assets/images/background.jpg'
import logoWhite from '../assets/images/logo-white.png'

import db from '../services/firebase';
import { addDoc, collection } from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytes } from "firebase/storage";
// import NavBar from '../components/NavBar';

const JOBS = [
    {
        id: 0,
        title: 'Software Engineer: Backend',
        about: 'Join Qurre Health in revolutionizing smart–technology to deliver quality, affordable, and effective human-centered healthcare in Africa.',
        duration: 'Contract based',
        hours: '8hrs/day (full-time)',
        location: 'remote',
        qualifications: [
            "Mandatory skills: JavaScript/Typescript, Node JS, Adept in crafting REST APIs",
            "Currently pursuing or have a Bachelor's degree in Computer Science or related technical field, or equivalent practical experience.",
            "Experience in software development.",
            "Excellent problem solving, coding, and debugging skills",
            "Experience with creating and working with REST APIs.",
            "Experience working with NoSQL databases",
            "In-depth understanding of data structures and other computer science fundamentals.",
            "Experience with two or more of the following programming languages: JavaScript/TypeScript, Python, Java, Swift, Objective C.",
            "Experience with server-side web frameworks such as Express JS, Ruby-on-Rails, or Django.",
            "Familiarity with front end technologies such as React, HTML5 and CSS3",
            "Experience working with Google Firebase and Google Cloud Platform will be given a preferance",
            "Understanding of accessibility and server compliance",
            "Experience writing maintainable, well tested, reliable code",
            "Experience collaborating with version control systems such as Github.",
            "Ability to meet tight deadlines",
            "Good internet and laptop suitable for coding.",
            "Some experience with Machine learning is a plus.",
        ],
    },
    {
        id: 1,
        title: 'Software Engineering Internship: Web Development (Full-Stack)',
        about: 'Join Qurre Health in revolutionizing smart–technology to deliver quality, affordable, and effective human-centered healthcare in Africa.',
        duration: '3 months (with possible extension and eligibility for a full–time role based on performance)',
        hours: '8hrs/day (full-time)',
        location: 'remote',
        qualifications: [
            "Mandatory skills: JavaScript, React, HTML, CSS.",
            "Currently pursuing or have a Bachelor's degree in Computer Science or related technical field, or equivalent practical experience.",
            "Experience in software development.",
            "Excellent Grade 12 Results.",
            "Good internet and laptop suitable for coding.",
            "Experience with the following programming languages: JavaScript, Python.",
            "Experience with React and some React Frameworks such as Material UI.",
            "Experience with server-side web frameworks such as Express JS, Ruby-on-Rails, or Django.",
            "Experience with creating and working with APIs.",
            "Well-versed with Web standards and protocols, including  HTML, CSS, HTTP, and TLS.",
            "Experience collaborating with version control systems such as Github.",
            "Some experience with UI/UX design and development is a plus.",
            "Some experience with Machine learning is a plus."
        ],
    },
    {
        id: 2,
        title: 'Software Engineering Internship: Mobile Development (Full Stack)',
        about: 'Join Qurre Health in revolutionizing smart–technology to deliver quality, affordable, and effective human-centered healthcare in Africa.',
        duration: '3 months (with possible extension and eligibility for a full–time role based on performance)',
        hours: '8hrs/day (full-time)',
        location: 'remote',
        qualifications: [
            "Mandatory skills: JavaScript, React Native, CSS.",
            "Currently pursuing or have a Bachelor's degree in Computer Science or related technical field, or equivalent practical experience.",
            "Experience in software development.",
            "Excellent Grade 12 Results.",
            "Good internet and laptop suitable for coding.",
            "Experience with two or more of the following programming languages: JavaScript, Python, Java, Swift, Objective C.",
            "Experience with React Native and some React Native Frameworks such as React Native Elements.",
            "Experience with server-side web frameworks such as Express JS, Ruby-on-Rails, or Django.",
            "Experience with creating and working with APIs.",
            "Experience with styling using CSS or related frameworks.",
            "Experience collaborating with version control systems such as Github.",
            "Some experience with UI/UX design and development is a plus.",
            "Some experience with Machine learning is a plus."
        ],
    },
]

// Create a root reference
const storage = getStorage();
var resume = null;
function ApplicationForm() {
    const initState = {
        "Full Name": "",
        "Email Address": "",
        "Primary Phone": "",
        "City": "",
        "Country": "",
        "LinkedIn Profile": "",
        "Github": "",
        "Portfolio Website": "",
        "Resume": "",
        "Job Title": ""
    };
    const [errorState, setError] = useState("");
    const [state, setstate] = useState(initState);

    function handleChange(e) {
        const newstate = JSON.parse(JSON.stringify(state));
        newstate[e.target.name] = e.target.value;
        if (e.target.name === "Resume") {
            resume = e.target.files['0'];
        }
        setstate(newstate);
    }

    // function sendEmail() {
    //     var nodemailer = window.require('nodemailer');

    //     var transporter = nodemailer.createTransport({
    //         service: 'gmail',
    //         auth: {
    //             user: 'hello@qurrehealth.com',
    //             pass: '{qurreafrica}'
    //         }
    //     });

    //     var mailOptions = {
    //         from: 'hello@qurrehealth.com',
    //         to: state['Email Address'],
    //         subject: 'Your Application at Qurre Africa',
    //         text: `Hi ${state},\n\nWe are very excited to review your application and will reach out to you for the next steps if you are a good fit.\n\nThanks!\n\nThe Qurre Health Team`
    //     };

    //     transporter.sendMail(mailOptions, function (error, info) {
    //         if (error) {
    //             console.log(error);
    //         } else {
    //             console.log('Email sent: ' + info.response);
    //         }
    //     });
    // }

    function handleSubmit(event) {
        event.preventDefault();
        if (state["Full Name"].length * state["Email Address"].length * state["Primary Phone"].length * state["City"].length * state["Country"].length * state["Resume"].length === 0) {
            setError("Enter all required form information");
            return;
        }
        if (state["Job Title"].length === 0) {
            setError("Please click apply on a job posting above");
            return;
        }
        else {
            setError("You have successfully submitted your application. We will reach out if you are a good fit");
        }
        try {
            addDoc(collection(db, "Job Applications"), {
                state
            });
        }
        catch (e) {
            console.error("Error submitting application: ", e);
        }

        const storageRef = ref(storage, state["Email Address"]);
        uploadBytes(storageRef, resume).then(() => {
            console.log('Uploaded a file!');
        });
        setstate(initState);
        // sendEmail();
        console.log("Email Sent!");
    }

    const setColor = () => {
        return errorState.length > 0 && errorState[0] === 'Y' ? colors.success : colors.danger;
    };

    const button = {
        borderRadius: '25px',
        textTransform: 'capitalize',
        color: colors.white,
        margin: '30px 50px 0 50px',
        width: '100%',
        "&:hover": {
            backgroundColor: colors.black,
            color: colors.white,
        }
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const appFormDisplaySuccess = <Container id="ApplicationForm" sx={{ marginTop: 8, marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Paper component={"form"} elevation={4} sx={{ padding: '30px', maxWidth: '500px', borderRadius: 5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '10px', color: setColor() }}>
                You have successfully submitted your application. We will reach out if you are a good fit.
            </Box>
        </Paper>
    </Container>

    const appFormDisplay = <Container id="ApplicationForm" sx={{ marginTop: 8, marginBottom: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

        <Paper component={"form"} elevation={4} sx={{ padding: '30px', maxWidth: '500px', borderRadius: 5 }}>
            <Typography variant="p" component="h1"> Fill Out The Application</Typography>
            <Typography variant="p" component="p" sx={{ color: 'red' }}>* Required</Typography>

            <TextField
                disabled
                value={state["Job Title"].substring(33)}
                variant="standard"
                sx={{ width: '100%', margin: '25px 0' }}
            />

            <Box>
                <Typography variant="h6" component="h2"> Resume (CV) * </Typography>
                <Typography variant="p" component="p"> Please upload your resume in English (2MB max file size, PDF format). The resume should include at least the following sections:
                    Education,
                    Work Experience and/or Projects,
                    Software Skills.
                </Typography>
                <Button
                    variant="contained"
                    component="label"
                    sx={{ marginTop: '15px', textTransform: 'capitalize', }}
                >
                    Upload Resume
                    <input
                        type="file"
                        accept=".pdf"
                        value={state["Resume"]}
                        name="Resume"
                        onChange={handleChange}
                        hidden
                    ></input>
                </Button>
            </Box>

            <Box sx={{ marginTop: 4 }}>
                <Typography variant="h6" component="h2"> Personal Details </Typography>
                <Typography variant="p" component="p" sx={{ marginBottom: 2 }}> Please enter your name as it appears on your official documents, such as your NRC or passport.</Typography>

                <Stack sx={{
                    width: '100%',

                }}
                    component="form"
                    spacing={2}
                >
                    <TextField
                        required
                        id="standard-required"
                        label="Full Name"
                        name="Full Name"
                        value={state["Full Name"]}
                        onChange={handleChange}
                        variant="standard"
                    />
                    <TextField
                        required
                        id="standard-required"
                        label="Email Address"
                        name="Email Address"
                        value={state["Email Address"]}
                        onChange={handleChange}
                        variant="standard"
                        type="email"
                    />
                    <TextField
                        required
                        id="standard-required"
                        label="Primary Phone"
                        name="Primary Phone"
                        value={state["Primary Phone"]}
                        onChange={handleChange}
                        variant="standard"
                    />
                    <TextField
                        required
                        id="standard-required"
                        label="City"
                        name="City"
                        value={state["City"]}
                        onChange={handleChange}
                        variant="standard"

                    />
                    <TextField
                        required
                        id="standard-required"
                        label="Country"
                        name="Country"
                        value={state["Country"]}
                        onChange={handleChange}
                        variant="standard"
                    />
                </Stack>

                <Box sx={{ marginTop: 4 }}>
                    <Typography variant="h6" component="h2"> Optional </Typography>

                    <Stack sx={{
                        width: '100%',
                    }}
                        component="form"
                        spacing={2}
                    >
                        <TextField
                            label="LinkedIn Profile (URL)"
                            name="LinkedIn Profile"
                            variant="standard"
                            value={state["LinkedIn Profile"]}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Github (URL)"
                            name="Github"
                            variant="standard"
                            value={state["Github"]}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Portfolio Website"
                            name="Portfolio Website"
                            variant="standard"
                            value={state["Portfolio Website"]}
                            onChange={handleChange}
                        />
                    </Stack>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" sx={button} onClick={handleSubmit}>Submit Application</Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '10px', color: setColor() }}>{errorState}</Box>

        </Paper>

    </Container>

    const displayThis = errorState === "You have successfully submitted your application. We will reach out if you are a good fit" ? appFormDisplaySuccess : appFormDisplay;

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: isMobile ? '50px' : '100px', backgroundColor: 'white', overflowX: 'hidden' }}>
                <Box sx={{ display: isMobile ? '' : 'flex', marginBottom: '20px' }}>
                    <Box>
                        <Typography variant={isMobile ? "h4" : "h2"} component="span" sx={{ color: colors.primary, fontWeight: '700', marginRight: '15px' }}> Build </Typography>
                        <Typography variant={isMobile ? "h4" : "h2"} component="span" sx={{ fontWeight: '700', marginRight: '15px' }}> Smart </Typography>
                    </Box>
                    <Box>
                        <Typography variant={isMobile ? "h4" : "h2"} component="span" sx={{ color: isMobile ? colors.black : colors.primary, fontWeight: '700', marginRight: '' }}> Health </Typography>
                        <Typography variant={isMobile ? "h4" : "h2"} component="span" sx={{ color: isMobile ? colors.primary : colors.black, fontWeight: '700' }}> Technology </Typography>
                    </Box>
                    {/* <Typography variant={isMobile ? "h3" : "h2"} component="h2" sx={{ color: colors.primary, fontWeight: '700', marginRight: '15px' }}> Build </Typography>
                    <Typography variant={isMobile ? "h3" : "h2"} component="h2" sx={{ fontWeight: '700', marginRight: '15px' }}> Smart </Typography>
                    <Typography variant={isMobile ? "h3" : "h2"} component="h2" sx={{ color: colors.primary, fontWeight: '700', marginRight: '15px' }}> Health </Typography>
                    <Typography variant={isMobile ? "h3" : "h2"} component="h2" sx={{ fontWeight: '700', textAlign: 'center' }}> Technology </Typography> */}
                </Box>
                <Box>
                    <img src={backgroundImg} alt="people collaborating on computer screens" style={{ height: '300px', borderRadius: '10px' }} />
                </Box>
            </Box>
            {
                JOBS.map((job) => {
                    return <JobPosting setState={setstate} state={state} key={job.id} spec={job} />
                })
            }

            {displayThis}

        </>
    )

}

function Careers() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        logo: {
            flexGrow: 1,
            fontWeight: 900,
            color: colors.white,
            textDecoration: 'none',
            marginLeft: isMobile ? 2 : 6,
        },
        image: {
            width: '100px',
            height: '100px'
        }
    }


    return <div>
        {/* <NavBar /> */}
        <AppBar position="sticky">
            <Toolbar>
                <Link variant="h5" sx={styles.logo}>
                    Qurre Careers
                </Link>
                <Link variant="h5"
                    sx={{ display: 'flex', marginRight: isMobile ? 2 : 6 }}
                    component={RouterLink}
                    to="/" >
                    <img src={logoWhite} alt="black Qurre Health logo" style={{ height: '50px' }} />
                </Link>
            </Toolbar>
        </AppBar>

        <Container sx={{ marginTop: isMobile ? '25px' : '50px', display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>

            <ApplicationForm />

        </Container>;
        <Footer />
    </div>
}

export default Careers;
