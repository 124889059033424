import React from 'react'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorImage from '../assets/images/404.jpg'

const PageNotFound = () => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        h1: {
            fontSize: '50px',
            textAlign: 'center',
            marginTop: '25px'
        },
        img: {
            height: '90vh',
        }
    }

    return (
        <Container sx={styles.container}>
            <Box>
                <Typography variant="h1" component="h1" sx={styles.h1}> The page you are trying to reach does not exist. </Typography>
            </Box>
            <Box>
                <img src={ErrorImage} alt="Page Not Found Error" style={styles.img} />
            </Box>
        </Container>
    )
}

export default PageNotFound;
