import React from 'react'

import NavBar from '../components/NavBar';
import HowItWorks from '../components/HowItWorks';
import Meet from '../components/Meet';
import Footer from '../components/Footer';
import OurMission from '../components/OurMission';


export default function Home() {
    return (
        <div id="home" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100vw', overflowX: 'hidden' }}>
            <NavBar title="Qurre" showSections={true} />
            <HowItWorks />
            <Meet />
            <OurMission />
            <Footer />
        </div>
    )
}
