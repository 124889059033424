import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

import { Link as LinkScroll } from 'react-scroll';

import colors from '../utils/colors';


export default function NavBar({ showSections, title }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerState, setdrawerState] = useState(false);

    const displayMobile = () => {

        const styles = {
            logo: {
                flexGrow: 1,
                fontWeight: 700,
                color: colors.white,
                textDecoration: 'none',
            },
            link: {
                color: colors.white,
                marginBottom: '15px',
                fontWeight: 700,
                fontSize: '1.5rem'
            },
            button: {
                borderRadius: '25px',
                textTransform: 'capitalize',
                color: colors.primary,
                padding: '10px 24px',
                width: '70%',
                marginTop: '15px'
            },
            image: {
                width: '25px',
                height: '25px',
            }
        }

        return (
            <Toolbar>
                <Link variant="h4" href="#home" sx={styles.logo}>
                    {title}
                </Link>
                <IconButton edge="end"
                    aria-label="menu"
                    aria-haspopup="true"
                    onClick={() => setdrawerState(true)}
                    size="large"
                >
                    <MenuIcon color="info" />
                </IconButton>
                <Drawer
                    anchor="top"
                    open={drawerState}
                    onClose={() => setdrawerState(false)}
                    PaperProps={{ sx: { backgroundColor: colors.primary, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } }}
                >
                    <Link
                        href="#meet-qurre"
                        sx={styles.link}
                        onClick={() => setdrawerState(false)}
                    >
                        Meet Qurre
                    </Link>
                    <Link
                        href="#meet-qurre"
                        sx={styles.link}
                        onClick={() => setdrawerState(false)}
                    >
                        How It Works
                    </Link>
                    <Link
                        href="#our-mission"
                        sx={styles.link}
                        onClick={() => setdrawerState(false)}
                    >
                        Our Mission
                    </Link>
                    <Link
                        component={RouterLink}
                        to="/careers"
                        sx={styles.link}
                        onClick={() => setdrawerState(false)}
                    >
                        Careers
                    </Link>
                    <Button color="info" variant="contained" sx={styles.button}>
                        <Link
                            href="#join-waitlist"
                            sx={{ textDecoration: 'none', fontSize: '1.3rem' }}
                            onClick={() => setdrawerState(false)}
                        >
                            Join Waitlist
                        </Link>
                    </Button>

                </Drawer>
            </Toolbar>
        );
    }

    const displayDesktop = () => {

        const styles = {
            link: {
                fontFamily: `"Lato", sans-serif`,
                textDecoration: 'none',
                marginRight: '10px',
                marginLeft: '10px',
                fontWeight: '400',
                "&:hover": {
                    color: colors.black,
                },
                cursor: 'pointer'
            },
            button: {
                borderRadius: '25px',
                textTransform: 'capitalize',
                color: colors.primary,
                padding: '6px 24px',
                marginRight: 10,
                "&:hover": {
                    backgroundColor: colors.black,
                    color: colors.white,
                }
            },
            logo: {
                flexGrow: 1,
                fontWeight: 900,
                color: colors.white,
                textDecoration: 'none',
                marginLeft: 10,
            },
            image: {
                width: '50px',
                height: '50px',
                marginRight: 2,
            }
        }

        return (

            <Toolbar>
                <Link variant="h4" sx={styles.logo}>
                    <LinkScroll
                        activeClass="active"
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={0}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {/* <img src={logoBlack} alt="Black Qurre Logo" style={styles.image} />
                        <p>urre</p> */}
                        {showSections ? 'Qurre' : 'Qurre Careers'}
                    </LinkScroll>
                </Link>
                {
                    showSections &&
                    <Box sx={{ flexGrow: 1 }}>
                        <Link color={colors.white} sx={styles.link}>
                            <LinkScroll
                                activeClass="active"
                                to="meet-qurre"
                                spy={true}
                                smooth={true}
                                offset={-50}
                            >
                                Meet Qurre
                            </LinkScroll>
                        </Link>
                        <Link color={colors.white} sx={styles.link}>
                            <LinkScroll
                                activeClass="active"
                                to="how-it-works"
                                spy={true}
                                smooth={true}
                                offset={0}
                            >
                                How It Works
                            </LinkScroll>
                        </Link>
                        <Link color={colors.white} sx={styles.link}>
                            <LinkScroll
                                activeClass="active"
                                to="our-mission"
                                spy={true}
                                smooth={true}
                                offset={-75}
                            >
                                Our Mission
                            </LinkScroll>
                        </Link>
                        <Link color={colors.white} sx={styles.link} component={RouterLink} to="/careers">
                            Careers
                        </Link>
                    </Box>
                }
                <Button color="info" variant="contained" sx={styles.button}>
                    <LinkScroll
                        activeClass="active"
                        to="join-waitlist"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={750}
                    >
                        Join Waitlist
                    </LinkScroll>
                </Button>
            </Toolbar>

        );
    }


    return (
        <AppBar position="sticky">
            {
                isMobile ? displayMobile() : displayDesktop()
            }
        </AppBar>
    );
};
