import React from 'react'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import Button from '@mui/material/Button';

import JoinWaitList from './JoinWaitList';

export default function Footer() {
    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.primary,
        padding: '70px 10px 20px 10px',
        width: '100%',
        height: '100%'
    }
    return (
        <Box sx={styles}>
            <JoinWaitList />
            <Typography variant="h2" component="h2" sx={{ fontWeight: 900, color: colors.white, marginBottom: '30px' }}>
                Qurre
            </Typography>
            <Box>
                <Button color="info" variant="text" href="https://www.facebook.com/Qurrehealth">
                    Facebook
                </Button>
                <Button color="info" variant="text" href="https://www.instagram.com/qurrehealth/">
                    Instagram
                </Button>
                <Button color="info" variant="text" href="">
                    Twitter
                </Button>
                <Button color="info" variant="text" href="https://www.linkedin.com/company/qurre/">
                    LinkedIn
                </Button>
            </Box>
            <Typography sx={{ marginTop: '70px', fontSize: '0.8rem' }}>
                Copyright &copy; 2021 | Qurre Inc. All Rights Reserved.
            </Typography>
        </Box>
    )
}
