import React from 'react'

import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Section from './Section'
import image1 from '../assets/images/Group 1.png'
import image2 from '../assets/images/Group 2.png'
import image3 from '../assets/images/image 7.png'
import image4 from '../assets/images/image 8.png'
import image5 from '../assets/images/image 9.png'

import colors from '../utils/colors';

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Meet() {

    AOS.init({
        duration: 500,
    });

    return (
        <Container id="meet-qurre" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3" sx={{ fontWeight: 700, margin: '30px 5px 50px 5px', color: colors.primary }}>Meet Qurre</Typography>
            <Section
                imgSrc={image1}
                imgPosition="left"
                title="Talk to experienced and licensed specialists anytime, anywhere."
                description="Accessing health care just got easy! Connect to a wide range of health care specialists– from cardiologists to surgeons– right at your fingertips. "
            />
            <Divider sx={{ width: '100%' }} />

            <Grid py={1} xs={12} data-aos="zoom-in">
                <img width="100%" src={image2} alt="Qurre App Screen" />
            </Grid>
            <Divider sx={{ width: '100%' }} />

            <Section
                imgSrc={image3}
                imgPosition="right"
                title="Designed for You, Your life,and Your schedule"
                description="Qurre empowers patients to live healthier and comfortable lives by providing simple to use and accessible health care right at their fingertips. Qurre is designed for patients, their lives, and their schedules. "
            />
            <Divider sx={{ width: '100%' }} />


            <Section
                imgSrc={image4}
                imgPosition="left"
                title="Highly Personalized Care"
                description="Stay in touch with your doctors and develop a strong and supportive connection. Easy connection via text, audio, and video calls."
            />
            <Divider sx={{ width: '100%' }} />

            <Section
                imgSrc={image5}
                imgPosition="right"
                title="Highly Experienced Doctors on Board."
                description="Doctors on Qurre are experienced, certified, and have a high patient satisfaction rating. The choice is yours, select your best match!"
            />
            <Divider sx={{ width: '100%' }} />

        </Container>
    )
}
