import React, { useState } from 'react'

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import colors from '../utils/colors';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

import {addDoc, collection} from 'firebase/firestore/lite';
import db from '../services/firebase';

export default function JoinWaitList() {

    const [email, setEmail] = useState("");

    const styles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '30px',
        marginBottom: '50px',
        borderRadius: '25px',
        backgroundColor: colors.white
    }

    const button = {
        borderRadius: '25px',
        textTransform: 'capitalize',
        color: colors.white,
        padding: '6px 24px',
        marginRight: 10,
        width: '100%',
        "&:hover": {
            backgroundColor: colors.black,
            color: colors.white,
        }
    }

    function handleSubmit() {
        try 
        {
            addDoc(collection(db, "Subscriptions"), {
              email
            });
        } 
        catch (e) 
        {
            console.error("Error adding document: ", e);
        }
        setEmail("");
    }
    return (

        <Grid container sm={12} md={8} sx={styles} id="join-waitlist">
            <Grid item sm={12} md={4}>
                <Typography variant="h5" sx={{ fontWeight: 700, color: colors.primary }}>
                    Join Qurre's Waitlist
                </Typography>
                <Typography variant="subtitle1">
                    Subscribe to stay upto date with Qurre and be the first to know when we launch.
                </Typography>
            </Grid>
            <Grid item container sm={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item sm={12} md={8} my={2} sx={{ width: '100%' }}>
                    <Input type="email" autoComplete required placeholder="Email" sx={{ width: '100%' }} value={email} onChange={(e) => setEmail(e.target.value)}/>
                </Grid>
                <Grid item sm={12} md={4} my={2}>
                    <Button variant="contained" sx={button} onClick={handleSubmit}>Join Waitlist</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
