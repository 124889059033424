import React from 'react'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Section({ imgSrc, imgPosition, title, description }) {

    AOS.init({
        duration: 500,
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const imgLeft = <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5} py={10}>
        <Grid item xs={12} md={6} data-aos="slide-right">
            <img width="100%" src={imgSrc} alt="Qurre App Screen" />
        </Grid>
        <Grid item xs={12} md={6} data-aos="slide-left">
            <Typography variant="h3" sx={{fontSize: isMobile ? 35: 70}} component="h2">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
        </Grid>
    </Grid>


    const imgRight = <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5} py={10}>
        <Grid item xs={12} md={6} data-aos="slide-right">
            <Typography variant="h3" sx={{fontSize: isMobile ? 35: 70}} component="h2">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid item xs={12} md={6} data-aos="slide-left">
            <img width="100%" src={imgSrc} alt="Qurre App Screen" />
        </Grid>
    </Grid>

    return (
        <>
            {imgPosition === "left" ? imgLeft : imgRight}
        </>

    )
}
