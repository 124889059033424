import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import colors from '../utils/colors';

function JobPosting({ spec, setState, state }) {

    const styles = {
        button: {
            borderRadius: '5px',
            textTransform: 'capitalize',
            color: colors.white,
            padding: '10px 24px',
            width: '100px',
        },
    }

    function handleClick() {
        const newstate = JSON.parse(JSON.stringify(state));
        newstate["Job Title"] = spec.title;
        setState(newstate);
    }

    return (
        <Container sx={{ marginBottom: '20px' }}>

            <Paper elevation={1} sx={{ padding: '30px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="p" component="h1" sx={{ color: colors.black, fontSize: '1.75rem' }}>
                        {spec.title}
                    </Typography>
                    <Button color="primary" variant="contained" href="#ApplicationForm" sx={styles.button} onClick={handleClick} disabled={spec.id !== 0 ? true : false}>
                        Apply
                    </Button>
                </Box>


                <Box sx={{ marginTop: '30px' }}>
                    <Typography variant="p" component="h2" sx={{ color: colors.black, fontSize: '1.5rem' }}>About the job: </Typography>
                    {spec.about}
                </Box>


                <Box sx={{ marginTop: '20px' }}>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Duration: </Typography>
                        {spec.duration}
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Number of hours: </Typography>
                        {spec.hours}
                    </Box>
                    <Box>
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>Location: </Typography>
                        {spec.location}
                    </Box>
                </Box>

                <Box sx={{ marginTop: '30px' }}>
                    <Typography variant="p" component="h2" sx={{ color: colors.black, fontSize: '1.5rem' }}>Qualifications</Typography>
                    <ul>
                        {spec.qualifications.map((item, index) => <li style={{ color: index === 0 ? colors.danger : colors.black }}>{item}</li>)}
                    </ul>

                </Box>

            </Paper>

        </Container>
    );
}

export default JobPosting;
