import { initializeApp } from "firebase/app";
import { getFirestore} from 'firebase/firestore/lite';

/*
const firebaseConfig = {
  apiKey: "AIzaSyDxtNWkqx3Mf3eAqsjid_Zo3rspyd1vRh8",
  authDomain: "yaluna-290b8.firebaseapp.com",
  projectId: "yaluna-290b8",
  storageBucket: "yaluna-290b8.appspot.com",
  messagingSenderId: "312928024920",
  appId: "1:312928024920:web:7bb7347d27e8a7d9b14d2d",
  measurementId: "G-X7KSK8KY8B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const collectEmail = async (email) => {

  // TODO 
  // input validation

  const db = getFirestore(app);
  try {
    await addDoc(collection(db, "yaluna-emails"), {
      email
    });
    // console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

*/

const firebaseConfig = {
  apiKey: "AIzaSyCXH6e6_2zswGhRzLkweIB8u2CBvCd8azQ",
  authDomain: "qurre-job-apps.firebaseapp.com",
  projectId: "qurre-job-apps",
  storageBucket: "qurre-job-apps.appspot.com",
  messagingSenderId: "681628365028",
  appId: "1:681628365028:web:0a933f0ad7f6e34510fcf1",
  measurementId: "G-9PYYDPEXQC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

const db = getFirestore(app);
export default db;
