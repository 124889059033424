import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import './App.css';

import Home from './views/Home'
import Careers from './views/Careers';
import PageNotFound from './views/PageNotFound';

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: `"Lato", sans-serif`,
    },
    palette: {
      primary: {
        main: '#3e64ff',
      },
      secondary: {
        main: '#000000',
      },
      info: {
        main: '#ffffff',
      }
    },
  });

  // You can manage your secrets at https://github.com/MikeMula/QurreHealth/settings/secrets
  // just testing

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
