import React from 'react'

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import animation from '../assets/images/video4.gif';
import colors from '../utils/colors';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import { Link as LinkScroll } from 'react-scroll';

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function HowItWorks() {

    AOS.init({
        duration: 500,
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        backgroundColor: colors.primary,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: isMobile ? '50px 0' : '100px 0',
        width: '100%'
    }

    const button = {
        borderRadius: '25px',
        textTransform: 'capitalize',
        color: colors.primary,
        padding: '10px 24px',
        width: '70%',
        marginTop: '15px'
    }

    return (
        <Box sx={styles} id="how-it-works">

            <Typography variant="h2" component="h1" sx={{fontSize: isMobile ? 30: 50, fontWeight: 900, margin: isMobile ? '0 0 15px 0' : '0 50px 10px 50px', color: colors.white }}>
                Health Care At Your
            </Typography>
            <Typography variant="h2" component="h1" sx={{fontSize: isMobile ? 30: 50, fontWeight: 900, margin: isMobile ? '0 0 15px 0' : '0 50px 50px 50px', color: colors.white }}>
                Fingertips
            </Typography>
            <Box data-aos="flip-left">
                <img src={animation} width="350" alt="Demo gif" />
            </Box>
            {
            isMobile && <Button color="info" variant="contained" sx={button}>
                <LinkScroll
                        activeClass="active"
                        to="join-waitlist"
                        spy={true}
                        smooth={true}
                        offset={-20}
                        duration={750}
                    >
                        Join Waitlist
                    </LinkScroll>
            </Button>
            }

        </Box>
    )
}
