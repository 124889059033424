import React from 'react'

import Grid from '@mui/material/Grid';
import colors from '../utils/colors';
import Typography from '@mui/material/Typography';

export default function OurMission() {

    const styles = {
        image: {
            borderRadius: '200px',
        }
    }
    return (
        <Grid id="our-mission" container justifyContent="center" alignItems="center" sm={12} md={8} my={2} py={4} px={2} sx={{ borderRadius: '15px' }}>
            <Grid item sm={12} md={6}>
                {/* <Typography variant="h3" component="h1" mb={10} sx={{ fontWeight: 900, color: colors.primary }}>
                    Our Mission
                </Typography> */}
                <Typography variant="h4" sx={{ color: colors.primary, fontWeight: 900 }}>
                    To Inspire Better Health For Everyone Through Convenient, Personalized and Affordable Health Care.
                </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
                <img src="https://images.pexels.com/photos/5452201/pexels-photo-5452201.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" width="400px" alt="doctor" style={styles.image} />
            </Grid>
        </Grid>
    )
}
